import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { permissionLevels } from '../utils/enums';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../utils/capitalize';
import Icon from '../components/Icon';
import Footer from '../components/Footer';
import GhostEmoji from '../components/GhostMoji';
import { getTodayPeriod } from '../utils/dates';

const NavBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const { isAuthenticated, user } = useAuth();
    const { t } = useTranslation();
    const location = useLocation();

    const pages = [
        { name: t('needs'), link: '/needs', permsLevel: permissionLevels.CONDUC },
        { name: t('planning'), link: '/planning', permsLevel: permissionLevels.OBSERVERS },
        { name: t('supervision'), link: '/supervision', permsLevel: permissionLevels.CONDUC },
        { name: t('reporting'), link: '/reporting', permsLevel: permissionLevels.SUPERVISION },
        { name: t('admin'), link: '/admin', permsLevel: permissionLevels.PLANNING },
    ];
    const settings = [{ name: 'Profile', link: '/profile' }];

    const getProfileText = (permsLvl: number) => {
        switch (permsLvl) {
            case permissionLevels.ADMIN:
                return t('admin');
            case permissionLevels.SUPERVISION:
                return t('supervision');
            case permissionLevels.PLANNING:
                return t('planning');
            case permissionLevels.CONDUC:
                return t('conduc');
            case permissionLevels.OBSERVERS:
                return t('observers');
            case permissionLevels.TERRAIN:
                return t('terrain');
            case permissionLevels.CHAUFFEUR:
                return t('chauffeur');
            default:
                return t('');
        }
    };

    const getLogoByPeriod = () => {
        const period = getTodayPeriod(new Date());

        if (period === 'normal') return require('../assets/images/my-sltp-transp.png');
        if (period === 'halloween') return require('../assets/images/my-sltp-transp-halloween.png');
        if (period === 'christmas') return require('../assets/images/my-sltp-transp-christmas.png');
        if (period === 'newYear') return require('../assets/images/my-sltp-transp-newyear.png');

        return require('../assets/images/my-sltp-transp.png');
    };

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <div hidden={!isAuthenticated}>
            <AppBar position="relative" style={{ backgroundColor: 'black' }}>
                <Container maxWidth={false}>
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component={Link}
                            to="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                            }}
                        >
                            <img height={29} src={getLogoByPeriod()} />
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <Icon name="menu" onClick={handleOpenNavMenu} dark />
                            <Typography
                                variant="h6"
                                noWrap
                                component={Link}
                                to="/"
                                sx={{
                                    ml: 2,
                                    display: { xs: 'flex', md: 'none' },
                                }}
                            >
                                <img height={29} src={require('../assets/images/my-s-transp.png')} />
                            </Typography>
                            {isAuthenticated && (
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {pages.map(
                                        (page) =>
                                            user &&
                                            page.permsLevel >= user.profileId.permsLevel && (
                                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                                    <Typography
                                                        textAlign="center"
                                                        component={Link}
                                                        to={page.link}
                                                        sx={{
                                                            fontWeight: location.pathname === page.link ? 'bold' : 'normal',
                                                        }}
                                                    >
                                                        {page.name}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                    )}
                                </Menu>
                            )}
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {isAuthenticated && (
                                <>
                                    {pages.map(
                                        (page) =>
                                            user &&
                                            page.permsLevel >= user?.profileId.permsLevel && (
                                                <Button
                                                    key={page.name}
                                                    component={Link}
                                                    to={page.link}
                                                    onClick={handleCloseNavMenu}
                                                    sx={{
                                                        my: 2,
                                                        color: 'white',
                                                        display: 'block',
                                                        fontWeight: location.pathname === page.link ? 'bold' : 'normal',
                                                    }}
                                                >
                                                    {page.name}
                                                </Button>
                                            )
                                    )}
                                </>
                            )}
                        </Box>
                        <Box sx={{ flexGrow: 0, display: 'flex' }}>
                            <IconButton
                                onClick={isAuthenticated ? () => window.location.replace('/profile') : () => window.location.replace('/login')}
                                sx={{ p: 0 }}
                            >
                                <Avatar alt="Profile" color={user?.color} style={{ backgroundColor: user?.color }}>
                                    {user?.firstName.charAt(0)}
                                </Avatar>
                            </IconButton>

                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {user && (
                                    <div style={{ paddingLeft: 30 }}>
                                        <div>
                                            {user?.firstName} {user?.lastName}
                                        </div>
                                        <div>{capitalizeFirstLetter(getProfileText(user?.profileId.permsLevel))}</div>
                                    </div>
                                )}
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
};
export default NavBar;
