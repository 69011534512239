import React, { useState } from 'react';
import { DataItem } from '../../services/apiSLTP/activities';
import DroppableCell from '../DroppableCell';
import DraggableCell from '../DraggableCell';
import { updateDailyMachine } from '../../services/apiSLTP/activities';
import CreateDailyMachineModal from '../modals/ManageDailyMachineModal';
import { createDailyMachine, DailyMachine, deleteDailyMachine } from '../../services/apiSLTP/planning';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import Icon from '../Icon';
import { materialState } from '../../utils/enums';

interface MachinesCellProps {
    activity: DataItem;
    activities: DataItem[];
    activityIndex: number;
    disabled?: boolean;
    refetch?: () => void;
    hiddenPlusIcon?: boolean;
}

const MachinesCell: React.FC<MachinesCellProps> = ({
    activity,
    activityIndex,
    activities,
    disabled = false,
    refetch = () => {},
    hiddenPlusIcon = false,
}) => {
    const [selectedDailyMachine, setSelectedDailyMachine] = useState<DailyMachine | null>(null);
    const [showModal, setShowModal] = useState(false);
    const itemType = 'MACHINES';
    const { t } = useTranslation();

    const isDuplicateMachine = (machineId: string): boolean => {
        const matchingMachineIds = activities.flatMap((activity) => activity.dailyMachines).filter((dailyMachine) => dailyMachine.machineId._id === machineId);
        console.log(matchingMachineIds)
        if (matchingMachineIds.length === 1) return false;
        if (
            matchingMachineIds.length === 2 &&
            (matchingMachineIds[0].state === materialState.NEW || matchingMachineIds[1].state === materialState.NEW) &&
            (matchingMachineIds[0].state === materialState.REMOVE || matchingMachineIds[1].state === materialState.REMOVE)
        )
            return false;
        return true;
    };

    const handleMachinesChange = async (fromLineId: string, fromItemId: string, toLineId: string) => {
        if (fromLineId === toLineId) return;
        try {
            await updateDailyMachine(fromItemId, { activityId: toLineId });
            refetch();
        } catch (error) {
            console.error('Error updating daily machine:', error);
        }
    };

    const handleDeleteMachine = async (id: string, index: number) => {
        try {
            await deleteDailyMachine(id);
            refetch();
        } catch (error) {
            console.error('Error deleting daily machine:', error);
        }
    };

    const handleCreateMachine = async (dailyMachineId: string) => {
        const selectedMachine = activity.dailyMachines.find((value) => value._id === dailyMachineId);
        if (selectedMachine) {
            const response = await createDailyMachine(activity._id, selectedMachine.machineId._id, selectedMachine.state, true);
            refetch();
        }
    };

    return (
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
            <DroppableCell
                dropId={{ lineId: activity._id }}
                moveCell={(fromLineId, fromItemId, toLineId) => handleMachinesChange(fromLineId, fromItemId, toLineId)}
                itemType={itemType}
                disabled={disabled}
            >
                <div className="fx-r pos-re">
                    <div>
                        {!hiddenPlusIcon && (
                            <Icon
                                name="addCircle"
                                onClick={() => {
                                    setShowModal(true);
                                    setSelectedDailyMachine(null);
                                }}
                                disabled={disabled}
                                title={capitalizeFirstLetter(t('add'))}
                            />
                        )}
                    </div>
                    <div className="w-250px pad-l-5px">
                        {activity.dailyMachines.map((machine, index) => (
                            <DraggableCell
                                key={`${activityIndex}-${index}`}
                                dragIds={{ itemId: machine._id, lineId: activity._id }}
                                index={index}
                                itemType={itemType}
                                onDelete={() => handleDeleteMachine(machine._id, index)}
                                disabled={disabled}
                                state={machine.state}
                                onDuplicate={() => handleCreateMachine(machine._id)}
                                warning={isDuplicateMachine(machine.machineId._id)}
                                onDoubleClick={() => {
                                    setShowModal(true);
                                    setSelectedDailyMachine(machine);
                                }}
                            >
                                <div title={machine.machineId.designation} className="draggable-text">
                                    {machine.machineId.designation}
                                </div>
                            </DraggableCell>
                        ))}
                    </div>
                </div>
            </DroppableCell>
            {showModal && (
                <CreateDailyMachineModal
                    open={showModal}
                    activityId={activity._id}
                    handleClose={() => setShowModal(false)}
                    refetch={refetch}
                    dailyMachine={selectedDailyMachine}
                    activities={activities}
                    machine={null}
                />
            )}
        </div>
    );
};

export default MachinesCell;
