import React, { useState } from 'react';
import { DataItem } from '../../services/apiSLTP/activities';
import DroppableCell from '../DroppableCell';
import DraggableCell from '../DraggableCell';
import { updateDailyTool } from '../../services/apiSLTP/activities';
import CreateDailyToolModal from '../modals/ManageDailyToolModal';
import { createDailyTool, DailyTool, deleteDailyTool } from '../../services/apiSLTP/planning';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import { materialState } from '../../utils/enums';

interface ToolsCellProps {
    activity: DataItem;
    activities: DataItem[];
    activityIndex: number;
    disabled?: boolean;
    hiddenPlusIcon?: boolean;
    refetch?: () => void;
}

const ToolsCell: React.FC<ToolsCellProps> = ({
    activity,
    activityIndex,
    activities,
    disabled = false,
    hiddenPlusIcon = false,
    refetch = () => {},
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedDailyTool, setSelectedDailyTool] = useState<DailyTool | null>(null);
    const itemType = 'TOOLS';
    const { t } = useTranslation();

    const isDuplicateTool = (toolId: string): boolean => {
        const matchingToolIds = activities.flatMap((activity) => activity.dailyTools).filter((dailyTool) => dailyTool.toolId._id === toolId);
        if (matchingToolIds.length === 1) return false;
        if (
            matchingToolIds.length === 2 &&
            (matchingToolIds[0].state === materialState.NEW || matchingToolIds[1].state === materialState.NEW) &&
            (matchingToolIds[0].state === materialState.REMOVE || matchingToolIds[1].state === materialState.REMOVE)
        )
            return false;
        return true;
    };

    const handleToolsChange = async (fromLineId: string, fromItemId: string, toLineId: string) => {
        if (fromLineId === toLineId) return;
        try {
            await updateDailyTool(fromItemId, { activityId: toLineId });
            refetch();
        } catch (error) {
            console.error('Error updating daily tool:', error);
        }
    };

    const handleDeleteTool = async (id: string, index: number) => {
        try {
            await deleteDailyTool(id);
            refetch();
        } catch (error) {
            console.error('Error deleting daily tool:', error);
        }
    };

    const handleCreateDailyTool = async (dailyToolId: string) => {
        const selectedTool = activity.dailyTools.find((value) => value._id === dailyToolId);
        if (selectedTool) {
            const response = await createDailyTool(activity._id, selectedTool.toolId._id, selectedTool.state, true);
            refetch();
        }
    };

    return (
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
            <DroppableCell
                dropId={{ lineId: activity._id }}
                moveCell={(fromLineId, fromItemId, toLineId) => handleToolsChange(fromLineId, fromItemId, toLineId)}
                itemType={itemType}
                disabled={disabled}
            >
                <div className="fx-r pos-re">
                    <div>
                        {!hiddenPlusIcon && (
                            <Icon
                                name="addCircle"
                                onClick={() => {
                                    setShowModal(true);
                                    setSelectedDailyTool(null);
                                }}
                                disabled={disabled}
                                title={capitalizeFirstLetter(t('add'))}
                            />
                        )}
                    </div>
                    <div className="w-250px pad-l-5px">
                        {activity.dailyTools.map((tool, index) => (
                            <DraggableCell
                                key={`${activityIndex}-${index}`}
                                dragIds={{ itemId: tool._id, lineId: activity._id }}
                                index={index}
                                itemType={itemType}
                                onDelete={() => handleDeleteTool(tool._id, index)}
                                disabled={disabled}
                                state={tool.state}
                                warning={isDuplicateTool(tool.toolId._id)}
                                onDuplicate={() => handleCreateDailyTool(tool._id)}
                                onDoubleClick={() => {
                                    setShowModal(true);
                                    setSelectedDailyTool(tool);
                                }}
                            >
                                <div title={tool.toolId.designation} className="draggable-text">
                                    {tool.toolId.designation}
                                </div>
                            </DraggableCell>
                        ))}
                    </div>
                </div>
            </DroppableCell>
            {showModal && (
                <CreateDailyToolModal
                    open={showModal}
                    activityId={activity._id}
                    handleClose={() => setShowModal(false)}
                    refetch={refetch}
                    dailyTool={selectedDailyTool}
                    activities={activities}
                    tool={null}
                />
            )}
        </div>
    );
};

export default ToolsCell;
