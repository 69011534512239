import React, { useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyTruckRide } from '../../../services/apiSLTP/dailyReports/dailyTrucksRides';
import { DataItem, Report } from '../../../services/apiSLTP/activities';
import { getMaterialStateInfos, getStateInfos, getTruckModeInfos, reportState, truckRideActivityTypeId } from '../../../utils/enums';
import { getMaterialStateColor, getStateColor } from '../../../utils/colors';
import { countPropertyValues, removeDuplicates } from '../../../utils/array';
import Icon from '../../Icon';
import StateCell from '../../cells/StateCell';
import ToolsCell from '../../cells/ToolsCell';
import RentalsCell from '../../cells/RentalsCell';
import TrucksPlannedCell from '../../cells/TrucksPlannedCell';
import ColorBar from '../../ColorBar';
import SiteCell from '../../cells/SiteCell';
import CustomToggle from '../../CustomToggle';
import TruckIcon from '../../TruckIcon';
import MachinesCell from '../../cells/MachinesCell';
import { useAuth } from '../../../hooks/useAuth';
import { updateActivity } from '../../../services/apiSLTP/planning';
import { updateReportState } from '../../../services/apiSLTP/dailyReports/reports';
import { Truck } from '../../../services/apiSLTP/materials/trucks';
import TrucksRidesRow from './TrucksRidesRows';
import ActivityWithRidesRow from './ActivityWithRidesRow';

interface ReportsRowProps {
    report: Report;
    activities: DataItem[];
    trucks: Truck[];
    refetch: () => {};
    dailyTrucksRides: DailyTruckRide[];
    currentDate: Date;
}

const ReportsRow: React.FC<ReportsRowProps> = ({ report, activities, trucks, refetch, dailyTrucksRides, currentDate }) => {
    const { t } = useTranslation();
    const { user } = useAuth();

    const [isExpandable, setIsExpandable] = useState(false);

    const sortedActivities = () => {
        return activities
            .filter((activity) => activity.reportId._id === report._id)
            .sort((a, b) => {
                const timeA = new Date(a.startTime || '');
                const timeB = new Date(b.startTime || '');

                if (timeA < timeB) return -1;
                if (timeA > timeB) return 1;

                return 0;
            });
    };

    const formatTime = (time: string | undefined) => {
        if (time === undefined) return '';
        const date = new Date(time);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const handleActivityUnlock = async (activityId: string, reportId: string) => {
        await updateActivity(activityId, { activityState: reportState.REPORT });
        await updateReportState(reportId, reportState.REPORT);
        refetch();
    };

    const calculateStartTime = () => {
        let dates: Date[] = [];
        const filteredActivities = activities.filter((activity) => activity.reportId._id === report._id);
        for (const activity of filteredActivities) {
            if (activity.startTime) dates.push(new Date(activity.startTime));
        }
        for (const truckRide of dailyTrucksRides.filter((dailyTruckRide) =>
            filteredActivities.map((activity) => activity._id).includes(dailyTruckRide.activityId)
        )) {
            if (truckRide.departureTime) dates.push(new Date(truckRide.departureTime));
            if (truckRide.time) dates.push(new Date(truckRide.time));
        }
        if (!dates.length) return undefined;
        const minDate = new Date(Math.min(...dates.map((date) => date.getTime())));

        return minDate;
    };

    const calculateEndTime = () => {
        let dates: Date[] = [];
        const filteredActivities = activities.filter((activity) => activity.reportId._id === report._id);
        for (const activity of filteredActivities) {
            if (activity.validationTime) dates.push(new Date(activity.validationTime));
        }
        for (const truckRide of dailyTrucksRides.filter((dailyTruckRide) =>
            filteredActivities.map((activity) => activity._id).includes(dailyTruckRide.activityId)
        )) {
            if (truckRide.arrivalTime) dates.push(new Date(truckRide.arrivalTime));
            if (truckRide.time) dates.push(new Date(truckRide.time));
            if (truckRide.secondTime) dates.push(new Date(truckRide.secondTime));
        }
        if (!dates.length) return undefined;

        const maxDate = new Date(Math.max(...dates.map((date) => date.getTime())));

        return maxDate;
    };

    return (
        <React.Fragment key={report._id}>
            <tr onClick={() => setIsExpandable(!isExpandable)} className="font-w-700">
                <td className="pos-sy left-0 pad-0-imp z-idx-2">
                    <Icon name={isExpandable ? 'keyboardArrowDownSharp' : 'keyboardArrowRightSharp'} />
                </td>
                <td className="pos-sy left-30px z-idx-2">
                    <div>
                        {report.userId.lastName.toUpperCase()} {capitalizeFirstLetter(report.userId.firstName)}
                    </div>
                </td>
                <td>
                    <StateCell
                        stateColor={getStateColor(report.reportState)}
                        text={capitalizeFirstLetter(t(getStateInfos(report.reportState)))}
                        style={{ width: 150 }}
                    />
                </td>
                <td className="pos-sy left-230px z-idx-2" />
                <td>{formatTime(calculateStartTime()?.toISOString())}</td>
                <td>{formatTime(calculateEndTime()?.toISOString())}</td>
                <td colSpan={3} />
                <td>
                    <div>{!!report.fuel && capitalizeFirstLetter(t('fuel')) + ' : ' + report.fuel + 'L'}</div>
                    <div>{!!report.km && capitalizeFirstLetter(t('km')) + ' : ' + report.km + 'KM'}</div>
                </td>
                <td colSpan={7} />
            </tr>
            {isExpandable &&
                sortedActivities().map((activity, index, array) => {
                    return (
                        <ActivityWithRidesRow
                            activities={array}
                            refetch={refetch}
                            trucks={trucks}
                            dailyTrucksRides={dailyTrucksRides}
                            currentDate={currentDate}
                            index={index}
                            activity={activity}
                        />
                    );
                })}
        </React.Fragment>
    );
};

export default ReportsRow;
