import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyTruckRide } from '../../../services/apiSLTP/dailyReports/dailyTrucksRides';
import { DataItem } from '../../../services/apiSLTP/activities';
import { getMaterialStateInfos, getTruckModeInfos, truckMode, truckRideActivityTypeId } from '../../../utils/enums';
import { getMaterialStateColor } from '../../../utils/colors';
import { removeDuplicates } from '../../../utils/array';
import Icon from '../../Icon';
import DriversAmplirollRow from './DriversAmplirollRow';

interface DriversAmplirollTableProps {
    dailyTrucksRides: DailyTruckRide[];
    activities: DataItem[];
    universalFilter: string;
}

const DriversAmplirollTable: React.FC<DriversAmplirollTableProps> = ({ dailyTrucksRides, activities, universalFilter }) => {
    const { t } = useTranslation();

    const activitiesMap = new Map(activities.map((activity) => [activity._id, activity]));

    const sortedDailyTrucksRides = () => {
        return removeDuplicates(
            dailyTrucksRides.filter((value) => {
                return (
                    activitiesMap.get(value.activityId)?.activityTypeId._id === truckRideActivityTypeId &&
                    (value.truckId.truckTypeId.designation.toLowerCase() === 'ampliroll' || value.truckId.truckTypeId.designation.toLowerCase() === 'porte-char')
                );
            }),
            ['activityId']
        ).sort((a, b) => {
            const reportA = activities.find((value) => value._id === a?.activityId)?.reportId._id || '';
            const reportB = activities.find((value) => value._id === a?.activityId)?.reportId._id || '';

            if (reportA < reportB) return -1;
            if (reportA > reportB) return 1;

            const activityA = a.activityId;
            const activityB = b.activityId;

            if (activityA < activityB) return -1;
            if (activityA > activityB) return 1;

            const immatA = a.truckId?.plateNumber ?? '';
            const immatB = b.truckId?.plateNumber ?? '';

            if (immatA < immatB) return -1;
            if (immatA > immatB) return 1;

            const siteA = activitiesMap.get(a.activityId)?.siteId?._id ?? '';
            const siteB = activitiesMap.get(b.activityId)?.siteId?._id ?? '';

            if (siteA < siteB) return -1;
            if (siteA > siteB) return 1;

            return 0;
        });
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr>
                        <th scope="col" className="w-30px pos-sy left-0 z-idx-4 pad-0-imp"></th>
                        <th scope="col" className="w-200px pos-sy left-30px z-idx-4">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('codeProC'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('company'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('plateNumber'))}
                        </th>
                        <th scope="col" className="w-200px pos-sy left-230px z-idx-4">
                            {capitalizeFirstLetter(t('driver'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('type'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('mode'))}
                        </th>
                        <th scope="col" className="w-200px">
                            <div className="source">{t('source: ')}</div>
                            <div>{t('loadNature')}</div>
                        </th>
                        <th scope="col" className="w-200px">
                            <div className="source">{t('source: ')}</div>
                            <div>{t('site')}</div>
                        </th>
                        <th scope="col" className="w-200px">
                            <div className="source">{t('source: ')}</div>
                            <div>{t('quarry')}</div>
                        </th>
                        <th scope="col" className="w-150px">
                            <div className="source">{t('source: ')}</div>
                            <div>{t('dump')}</div>
                        </th>
                        <th scope="col" className="w-150px">
                            <div className="source">{t('source: ')}</div>
                            <div>{t('tonnage')}</div>
                        </th>
                        <th scope="col" className="w-150px">
                            <div className="source">{t('source: ')}</div>
                            <div>{t('hour')}</div>
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('siteHour'))}
                        </th>
                        <th scope="col" className="w-200px">
                            <div className="dest">{t('destination: ')}</div>
                            <div>{t('loadNature')}</div>
                        </th>
                        <th scope="col" className="w-200px">
                            <div className="dest">{t('destination: ')}</div>
                            <div>{t('site')}</div>
                        </th>
                        <th scope="col" className="w-200px">
                            <div className="dest">{t('destination: ')}</div>
                            <div>{t('quarry')}</div>
                        </th>
                        <th scope="col" className="w-150px">
                            <div className="dest">{t('destination: ')}</div>
                            <div>{t('dump')}</div>
                        </th>
                        <th scope="col" className="w-150px">
                            <div className="dest">{t('destination: ')}</div>
                            <div>{t('tonnage')}</div>
                        </th>
                        <th scope="col" className="w-150px">
                            <div className="dest">{t('destination: ')}</div>
                            <div>{t('hour')}</div>
                        </th>
                        <th scope="col" className="w-150px">
                            {capitalizeFirstLetter(t('onSiteBackTime'))}
                        </th>
                        <th scope="col" className="w-300px">
                            {capitalizeFirstLetter(t('transfers'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('observations'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {sortedDailyTrucksRides().map((truckRide) => (
                        <DriversAmplirollRow
                            activities={activities}
                            dailyTruckRide={truckRide}
                            dailyTrucksRides={dailyTrucksRides.filter(
                                (dailyTrucRide) =>
                                    dailyTrucRide.activityId === truckRide.activityId &&
                                (dailyTrucRide.truckId.truckTypeId.designation.toLowerCase() === 'ampliroll' || dailyTrucRide.truckId.truckTypeId.designation.toLowerCase() === 'porte-char')
                            )}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DriversAmplirollTable;
