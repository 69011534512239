import React, { useState } from 'react';
import { DataItem } from '../../services/apiSLTP/activities';
import DroppableCell from '../DroppableCell';
import DraggableCell from '../DraggableCell';
import {
    createDailyTruckPlanned,
    updateDailyTruckPlanned,
    updateDailyTruckPlannedRank,
} from '../../services/apiSLTP/dailyReports/dailyTrucksPlanned';
import ManageDailyTruckPlannedModal from '../modals/ManageDailyTruckPlannedModal';
import { deleteDailyTruckPlanned } from '../../services/apiSLTP/dailyReports/dailyTrucksPlanned';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import Icon from '../Icon';
import { createDailyTransfer } from '../../services/apiSLTP/dailyReports/dailyTransfers';
import { getTruckModeInfos, materialState, truckMode } from '../../utils/enums';
import { getMaterialStateColor } from '../../utils/colors';
import DroppableSimpleCell from '../DroppableSimpleCell';
import { useUpdateDailyTrucksPlannedRankQuery } from '../../hooks/queries/useDailyTrucksPlannedQuery';
import {
    useUpdateDailyTruckPlannedMutation,
    useUpdateDailyTruckPlannedRankMutation,
    useUpdateDailyTruckPlannedRankUpDownMutation,
} from '../../hooks/mutations/useDailyTrucksPlannedMutation';
import { useQueryClient } from 'react-query';

interface TrucksPlannedRankCellProps {
    activity: DataItem;
    activities: DataItem[];
    activityIndex: number;
    disabled?: boolean;
    refetch?: () => void;
    loading: boolean;
    hiddenPlusIcon?: boolean;
    type?: 'Semie' | 'Ampliroll' | '8x4' | '';
}

const TrucksPlannedRankCell: React.FC<TrucksPlannedRankCellProps> = React.memo(
    ({ activity, activityIndex, disabled = false, refetch = () => {}, hiddenPlusIcon = false, type = '', activities, loading }) => {
        const [showModal, setShowModal] = useState(false);
        const [selectedId, setSelectedId] = useState('');
        const itemType = 'TRUCKSPLANNEDRANK';
        const { t } = useTranslation();

        const { mutate: updateRank, isLoading: isRankLoading, isSuccess } = useUpdateDailyTruckPlannedRankMutation();

        const {
            mutate: updateTruckPlanned,
            isSuccess: isUpdateTruckPlannedSuccess,
            isError: isUpdateTruckPlannedError,
            isLoading: isUpdateTruckPlannedLoading,
            error: updateTruckPlannedError,
        } = useUpdateDailyTruckPlannedMutation({
            onSettled: (data, variables) => {
                refetch();
            },
            onError: (error) => {
                console.error('Error updating truck planned:', error.message);
            },
        });

        const { mutate: updateTruckPlannedRankUpDown } = useUpdateDailyTruckPlannedRankUpDownMutation({ onSettled: () => refetch() });

        const handleTrucksPlannedChange = async (fromLineId: string, toLineId: string) => {
            if (fromLineId === toLineId) return;
            try {
                if (!isRankLoading && !loading) {
                    updateRank({ id: fromLineId, body: { targetItemId: toLineId } }, { onSettled: () => refetch() });
                }
            } catch (error) {
                console.error('Error updating truck planned:', error);
            }
        };

        const handleDeleteTruckPlanned = async (id: string, index: number) => {
            try {
                await deleteDailyTruckPlanned(id);
                refetch();
            } catch (error) {
                console.error('Error deleting truck planned:', error);
            }
        };

        const handleCreateDailyTruckPlanned = async (dailyTruckPlannedId: string) => {
            const selectedTruckPlanned = activity.dailyTrucksPlanned.find((value) => value._id === dailyTruckPlannedId);
            if (selectedTruckPlanned) {
                const response = await createDailyTruckPlanned({
                    activityId: activity._id,
                    trucksAmount: selectedTruckPlanned.trucksAmount,
                    departureLoadNatureId: selectedTruckPlanned.departureLoadNatureId?._id,
                    arrivalLoadNatureId: selectedTruckPlanned.arrivalLoadNatureId?._id,
                    departureDumpId: selectedTruckPlanned.departureDumpId?._id,
                    arrivalDumpId: selectedTruckPlanned.arrivalDumpId?._id,
                    departureQuarryId: selectedTruckPlanned.departureQuarryId?._id,
                    arrivalQuarryId: selectedTruckPlanned.arrivalQuarryId?._id,
                    departureSiteId: selectedTruckPlanned.departureSiteId?._id,
                    arrivalSiteId: selectedTruckPlanned.arrivalSiteId?._id,
                    mode: selectedTruckPlanned.mode,
                    ridesAmount: selectedTruckPlanned.ridesAmount,
                    truckTypeId: selectedTruckPlanned.truckTypeId._id,
                    truckCompanyId: selectedTruckPlanned.truckCompanyId?._id,
                });
                for (const transfer of selectedTruckPlanned.transfers) {
                    await createDailyTransfer({
                        dailyTrucksPlannedId: response.data._id,
                        state: transfer.state,
                        machineId: transfer.machineId?._id,
                        quarryId: transfer.quarryId?._id,
                        rentalCompanyId: transfer.rentalCompanyId?._id,
                        rentalName: transfer.rentalName,
                        siteId: transfer.siteId?._id,
                        toolId: transfer.toolId?._id,
                    });
                }
                refetch();
            }
        };

        return (
            <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                <div className="fx-r pos-re">
                    {/*<div>
                        {!hiddenPlusIcon && (
                            <Icon
                                name="addCircle"
                                onClick={() => {
                                    setShowModal(true);
                                    setSelectedId('');
                                }}
                                disabled={disabled}
                                title={capitalizeFirstLetter(t('add'))}
                            />
                        )}
                    </div>*/}
                    <div className="w-300px pad-l-5px fx-c">
                        {activity.dailyTrucksPlanned
                            .filter(
                                (value) =>
                                    type === '' ||
                                    value.truckTypeId.designation === type ||
                                    (value.truckTypeId.designation.toLowerCase() === 'porte-char' && type === 'Ampliroll')
                            )
                            .sort((a, b) => {
                                if ((a.rank || 1) < (b.rank || 1)) return -1;
                                if ((a.rank || 1) > (b.rank || 1)) return 1;
                                if ((a.mode || -1) < (b.mode || -1)) return -1;
                                if ((a.mode || -1) > (b.mode || -1)) return 1;
                                return 0;
                            })
                            .map((truckPlanned, index, array) => (
                                <div className="fx-r " key={truckPlanned._id}>
                                    <div className="fx-c fx-justify-c" style={{ display: disabled ? 'none' : 'flex' }}>
                                        <button
                                            style={{
                                                backgroundColor: (truckPlanned.rank ?? 1) <= 1 ? 'lightgray' : '#000',
                                                color: 'white',
                                                border: 'none',
                                                cursor: 'pointer',
                                                fontSize: '16px',
                                                fontWeight: '900',
                                                borderTopLeftRadius: 10,
                                                borderTopRightRadius: 10,
                                            }}
                                            disabled={(truckPlanned.rank ?? 1) <= 1}
                                            onClick={() => {
                                                updateTruckPlannedRankUpDown({
                                                    id: truckPlanned._id,
                                                    body: { action: 'down' },
                                                });
                                            }}
                                        >
                                            -
                                        </button>
                                        <div className="w-100 h-1px " />
                                        <button
                                            className="fx-c fx-items-c fx-justify-c"
                                            style={{
                                                backgroundColor: (truckPlanned.rank ?? 1) >= 30 ? 'lightgray' : '#000',
                                                color: 'white',
                                                border: 'none',
                                                cursor: 'pointer',
                                                fontSize: '16px',
                                                fontWeight: '900',
                                                textAlign: 'center',
                                                borderBottomLeftRadius: 10,
                                                borderBottomRightRadius: 10,
                                            }}
                                            disabled={(truckPlanned.rank ?? 1) >= 30}
                                            onClick={() => {
                                                updateTruckPlannedRankUpDown({
                                                    id: truckPlanned._id,
                                                    body: { action: 'up' },
                                                });
                                            }}
                                        >
                                            +
                                        </button>
                                    </div>
                                    <DroppableSimpleCell
                                        key={truckPlanned._id}
                                        dropId={{ lineId: truckPlanned._id, index }}
                                        moveCell={(fromLineId, toLineId) => handleTrucksPlannedChange(fromLineId, toLineId)}
                                        itemType={itemType + activity._id}
                                        disabled={disabled}
                                        index={index}
                                        onDelete={() => handleDeleteTruckPlanned(truckPlanned._id, index)}
                                        onDuplicate={() => handleCreateDailyTruckPlanned(truckPlanned._id)}
                                        onDoubleClick={() => {
                                            setShowModal(true);
                                            setSelectedId(truckPlanned._id);
                                        }}
                                    >
                                        {/*<div className='pad-5px'>
                                    <DraggableCell
                                        key={`${activityIndex}-${index}`}
                                        dragIds={{ itemId: truckPlanned._id, lineId: activity._id }}
                                        index={index}
                                        itemType={itemType}
                                        onDelete={() => handleDeleteTruckPlanned(truckPlanned._id, index)}
                                        disabled={disabled}
                                        onDuplicate={() => handleCreateDailyTruckPlanned(truckPlanned._id)}
                                        onDoubleClick={() => {
                                            setShowModal(true);
                                            setSelectedId(truckPlanned._id);
                                        }}
                                    >*/}
                                        <div
                                            title={`${truckPlanned.rank && '№' + truckPlanned.rank.toString().padStart(2, '0') + ' - '}${truckPlanned.trucksAmount} ${truckPlanned.truckTypeId.designation} ${truckPlanned.truckCompanyId?.designation || ''} ${truckPlanned.truckCompanyId?.designation.toUpperCase() === 'SLTP' && truckPlanned.truckId?.userId && ' - ' + capitalizeFirstLetter(truckPlanned.truckId?.userId.firstName) + ' ' + truckPlanned.truckId?.userId.lastName.toUpperCase()}`}
                                            className="pad-l-5px pad-r-5px w-100 fx-c "
                                        >
                                            <div className="one-line" style={{ fontWeight: 'bold' }}>
                                                {truckPlanned.rank && (
                                                    <span
                                                        className="border-radius-5px bg-white pad-l-3px pad-r-3px m-r-5px"
                                                        style={{
                                                            color:
                                                                array.filter((value) => value.rank === truckPlanned.rank).length > 1
                                                                    ? 'orange'
                                                                    : undefined,
                                                        }}
                                                    >
                                                        {'№' + truckPlanned.rank.toString().padStart(2, '0')}
                                                    </span>
                                                )}
                                                {truckPlanned.trucksAmount !== 1 && truckPlanned.trucksAmount + ' '}
                                                {truckPlanned.truckTypeId.designation} {truckPlanned.truckCompanyId?.designation || ''}
                                                {truckPlanned.truckCompanyId?.designation.toUpperCase() === 'SLTP' &&
                                                    truckPlanned.truckId?.userId &&
                                                    ' - ' +
                                                        capitalizeFirstLetter(truckPlanned.truckId?.userId.firstName) +
                                                        ' ' +
                                                        truckPlanned.truckId?.userId.lastName.toUpperCase()}
                                            </div>
                                            <div>📍{activities.find((value) => value._id === truckPlanned.activityId)?.siteId.name}</div>
                                            <div className="one-line" style={{ fontStyle: 'italic' }}>
                                                {truckPlanned.mode !== undefined &&
                                                    `${capitalizeFirstLetter(t(getTruckModeInfos(truckPlanned.mode)))} `}
                                                {truckPlanned.mode !== undefined &&
                                                    !!truckPlanned.ridesAmount &&
                                                    truckPlanned.ridesAmount > 0 &&
                                                    ' | '}
                                                {!!truckPlanned.ridesAmount &&
                                                    truckPlanned.ridesAmount > 0 &&
                                                    `${truckPlanned.ridesAmount} ${t('ride(s)')}`}
                                            </div>

                                            {[truckMode.APPRO, truckMode.POSE, truckMode.ROTATION, truckMode.DOUBLEFRET].includes(
                                                truckPlanned.mode === undefined ? -1 : truckPlanned.mode
                                            ) && (
                                                <div className="fx-c  pad-l-3px border-radius-5px m-b-5px" style={{ backgroundColor: '#EEE' }}>
                                                    {(truckPlanned.departureDumpId || truckPlanned.departureLoadNatureId) && (
                                                        <div className="one-line" style={{ fontStyle: 'italic' }}>
                                                            {truckPlanned.departureDumpId &&
                                                                `${capitalizeFirstLetter(t('dump'))} ${truckPlanned.departureDumpId?.designation} `}
                                                            {truckPlanned.departureLoadNatureId &&
                                                                `${truckPlanned.departureLoadNatureId.designation}`}
                                                        </div>
                                                    )}
                                                    {(truckPlanned.departureSiteId || truckPlanned.departureQuarryId) && (
                                                        <div className="one-line">
                                                            <b>{t('from') + ' : '}</b>
                                                            {truckPlanned.departureSiteId && truckPlanned.departureSiteId?.name}
                                                            {truckPlanned.departureQuarryId && truckPlanned.departureQuarryId?.designation}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {[
                                                truckMode.EVAC,
                                                truckMode.ENLEVEMENT,
                                                truckMode.ROTATION,
                                                truckMode.DOUBLEFRET,
                                                truckMode.BACKANDFORTH,
                                            ].includes(truckPlanned.mode === undefined ? -1 : truckPlanned.mode) && (
                                                <div className="fx-c  pad-l-3px border-radius-5px m-b-5px" style={{ backgroundColor: '#EEE' }}>
                                                    {(truckPlanned.arrivalDumpId?._id || truckPlanned.arrivalLoadNatureId?._id) && (
                                                        <div className="one-line" style={{ fontStyle: 'italic' }}>
                                                            {truckPlanned.arrivalDumpId &&
                                                                `${capitalizeFirstLetter(t('dump'))} ${truckPlanned.arrivalDumpId?.designation} `}
                                                            {truckPlanned.arrivalLoadNatureId && `${truckPlanned.arrivalLoadNatureId.designation}`}
                                                        </div>
                                                    )}
                                                    {(truckPlanned.arrivalSiteId || truckPlanned.arrivalQuarryId) && (
                                                        <div className="one-line">
                                                            <b>{t('to') + ' : '}</b>
                                                            {truckPlanned.arrivalSiteId && truckPlanned.arrivalSiteId?.name}
                                                            {truckPlanned.arrivalQuarryId && truckPlanned.arrivalQuarryId?.designation}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {truckPlanned.observations && (
                                                <div style={{ paddingTop: 2 }}>
                                                    <div>{truckPlanned.observations && truckPlanned.observations}</div>
                                                </div>
                                            )}
                                            <div>
                                                {truckPlanned.mode === truckMode.TRANSFER &&
                                                    truckPlanned.transfers.map((transfer) => (
                                                        <div
                                                            className="m-3px fx-c border-radius-5px smFont pad-l-3px"
                                                            style={{ backgroundColor: getMaterialStateColor(transfer.state) }}
                                                            key={transfer._id}
                                                        >
                                                            <div>
                                                                {transfer.machineId?.designation ||
                                                                    transfer.toolId?.designation ||
                                                                    transfer.rentalName + ' ' + transfer.rentalCompanyId?.designation}
                                                            </div>
                                                            <div>
                                                                <b>{transfer.state === materialState.NEW ? t('from') + ' : ' : t('to') + ' : '}</b>
                                                                {transfer.siteId?.name || transfer.quarryId?.designation}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        {/*</DraggableCell>
                                    </div>*/}
                                    </DroppableSimpleCell>
                                </div>
                            ))}
                    </div>
                </div>
                {showModal && (
                    <ManageDailyTruckPlannedModal
                        open={showModal}
                        activity={activity}
                        handleClose={() => setShowModal(false)}
                        refetch={refetch}
                        truckPlannedId={selectedId}
                        truckType={type}
                    />
                )}
            </div>
        );
    }
);

export default TrucksPlannedRankCell;
