import React, { useEffect, useState } from 'react';
import { DataItem } from '../services/apiSLTP/activities';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Site, updateActivity } from '../services/apiSLTP/planning';
import { useAuth } from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useActivitiesByDateQuery } from '../hooks/queries/useActivitiesQuery';
import { getStateInfos, localStorageKeys, permissionLevels, reportState, stepModes, truckRideActivityTypeId } from '../utils/enums';
import { getInOneDayDate, getOneDayAgoDate, getSpecificDateFromDate, getTomorrowDate } from '../utils/dates';
import { capitalizeEveryFirstWordLetter, capitalizeFirstLetter } from '../utils/capitalize';
import LinearProgress from '@mui/material/LinearProgress';
import { getStateColor, getTextColor } from '../utils/colors';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import WorkersTable from '../components/tables/WorkersTable';
import MachinesTable from '../components/tables/MachinesTable';
import RentalsTable from '../components/tables/RentalsTable';
import ToolsTable from '../components/tables/ToolsTable';
import TempWorkersTable from '../components/tables/TempWorkersTable';
import CalendarModal from '../components/modals/CalendarModal';
import { updateReportState } from '../services/apiSLTP/dailyReports/reports';
import { useDailyTrucksRidesQuery } from '../hooks/queries/useDailyTrucksRidesQuery';
import SitesRidesTable from '../components/tables/supervision/SitesRidesTable';
import { DailyTruckRide } from '../services/apiSLTP/dailyReports/dailyTrucksRides';
import CustomToggle from '../components/CustomToggle';
import TruckIcon from '../components/TruckIcon';
import { useTrucksQuery } from '../hooks/queries/useTrucksQuery';
import { Truck } from '../services/apiSLTP/materials/trucks';
import { getItemFromLocalStorage, storeItemToLocalStorage } from '../utils/localStorage';
import ColorBar from '../components/ColorBar';
import Header from '../components/Header';
import StateBadge from '../components/StateBadge';
import Icon from '../components/Icon';
import SubContractorsTable from '../components/tables/SubContractorsTable';
import InfosColorsModal from '../components/modals/InfosColorsModal';
import MachinesCell from '../components/cells/MachinesCell';
import ToolsCell from '../components/cells/ToolsCell';
import RentalsCell from '../components/cells/RentalsCell';
import ReportsTable from '../components/tables/supervision/ReportsTable';
import SitesAmplirollTable from '../components/tables/supervision/SitesAmplirollTable';
import DriversRidesTable from '../components/tables/supervision/DriversRidesTable';
import DriversAmplirollTable from '../components/tables/supervision/DriversAmplirollTable';
import SiteCell from '../components/cells/SiteCell';
import TrucksPlannedCell from '../components/cells/TrucksPlannedCell';
import StateCell from '../components/cells/StateCell';
import CustomTabs from '../components/CustomTabs';
import ReportTable from '../components/tables/supervision/ReportTable';
import SiteTable from '../components/tables/supervision/SiteTable';
import TrucksRidesRow from '../components/tables/supervision/TrucksRidesRows';
import ActivityWithRidesRow from '../components/tables/supervision/ActivityWithRidesRow';

const ReportsHistory: React.FC = () => {
    const [activities, setActivities] = useState<DataItem[]>([]);
    const [dailyTrucksRides, setDailyTrucksRides] = useState<DailyTruckRide[]>([]);
    const [currentDate, setCurrentDate] = useState(new Date(getItemFromLocalStorage(localStorageKeys.DATE) || getTomorrowDate()));
    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const [universalFilter, setUniversalFilter] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);
    const [onlyMySite, setOnlyMySite] = useState(false);
    const [trucks, setTrucks] = useState<Truck[]>([]);

    const { user } = useAuth();
    const { t } = useTranslation();
    const location = useLocation();

    const { data, isLoading, isError, isSuccess, isRefetching, refetch } = useActivitiesByDateQuery({
        date: currentDate.toISOString(),
        minState: reportState.REPORT,
        maxState: reportState.REPORTFILLED,
        stepMode: stepModes.REPORT,
    });
    const { data: trucksData, isLoading: trucksIsLoading, isError: trucksIsError, isSuccess: trucksIsSuccess } = useTrucksQuery({});

    const {
        data: dailyTrucksData,
        isLoading: dailyTrucksIsLoading,
        isError: dailyTrucksIsError,
        isSuccess: dailyTrucksIsSuccess,
        isRefetching: dailyTrucksIsRefetching,
        refetch: dailyTrucksRefetch,
    } = useDailyTrucksRidesQuery({
        date: currentDate,
    });

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
        storeItemToLocalStorage(localStorageKeys.SUPERVISIONTAB, newValue.toString());
    };

    const handleDateChange = (newDate: string) => {
        setCurrentDate(new Date(newDate));
        storeItemToLocalStorage(localStorageKeys.DATE, newDate);
    };

    const handleActivityUnlock = async (activityId: string, reportId: string) => {
        await updateActivity(activityId, { activityState: reportState.REPORT });
        await updateReportState(reportId, reportState.REPORT);
        refetch();
    };

    const handleMySiteFilterChange = (checked: boolean) => {
        setOnlyMySite(checked);
        storeItemToLocalStorage(localStorageKeys.SUPERVISIONMYSITES, checked.toString());
    };

    const filteredActivitiesForConducs = () => {
        if (!onlyMySite) return activities;
        else
            return activities.filter((activity) => {
                return activity.siteId.conducId._id === user?._id && !activity.reportId.userId.isSpecial;
            });
    };

    useEffect(() => {
        if (data && isSuccess) {
            setActivities(data);
        }
    }, [data]);

    useEffect(() => {
        if (dailyTrucksData && dailyTrucksIsSuccess) {
            setDailyTrucksRides(dailyTrucksData);
        }
    }, [dailyTrucksData]);

    useEffect(() => {
        refetch();
        const tab = getItemFromLocalStorage(localStorageKeys.SUPERVISIONTAB);
        setSelectedTab(parseInt(tab || '0'));
    }, [location, refetch]);

    useEffect(() => {
        if (trucksData && trucksIsSuccess) {
            setTrucks(trucksData);
        }
    }, [trucksData]);

    useEffect(() => {
        const checked = getItemFromLocalStorage(localStorageKeys.SUPERVISIONMYSITES);
        setOnlyMySite(checked === 'true');
    }, []);

    const handleExport = () => {
        const table = document.getElementById('csv-planning-obj') as HTMLTableElement;
        if (!table) return;

        const rows = Array.from(table.querySelectorAll('tr'));
        if (!rows) return;

        const csvData: string[] = [];

        rows.forEach((row, idx) => {
            if (row.style.display === 'none') return;
            if (row.id === 'fu-exclude') return;
            const cols = Array.from(row.querySelectorAll('th, td'))
                .map((col, index) => {
                    let textContent = col.textContent?.trim() || '';
                    let title = '';

                    const children = Array.from(col.querySelectorAll('*'));
                    children.forEach((child) => {
                        if (!textContent && child.textContent?.trim()) {
                            textContent = child.textContent.trim();
                        }
                        if (child.getAttribute('title')) {
                            title = child.getAttribute('title')?.trim() || '';
                        }
                    });

                    return (textContent !== '' ? textContent : title || '').replaceAll('\n', ' | ');
                })
                .join(';');
            csvData.push(cols);
        });

        const csvString = '\uFEFF' + csvData.join('\n');

        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        const currentDate = new Date();
        a.download = `reports-${currentDate.toISOString().split('T')[0]}.csv`;
        a.click();

        URL.revokeObjectURL(url);
    };

    return (
        <div className="fx-c h-100">
            <Header
                isSearchable
                title={t('supervision')}
                onSearch={(text) => setUniversalFilter(text)}
                searchValue={universalFilter}
                date={currentDate}
                onLeftArrowClick={() => handleDateChange(getOneDayAgoDate(currentDate).toISOString())}
                onRightArrowClick={() => handleDateChange(getInOneDayDate(currentDate).toISOString())}
            >
                <Icon
                    name="eventRepeat"
                    onClick={() => handleDateChange(new Date().toISOString())}
                    title={capitalizeFirstLetter(t('today'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="calendarMonth"
                    onClick={() => setShowCalendarModal(true)}
                    title={capitalizeFirstLetter(t('calendar'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <div className="divider" />
                <div className="fx-r bg-white fx-items-c pad-5px border-radius-5px">
                    <b className="pad-r-5px">{capitalizeFirstLetter(t('mySites'))}</b>
                    <CustomToggle checked={onlyMySite} onChange={handleMySiteFilterChange} style={{ height: 20 }} />
                </div>
                <div className="divider" />
                <Icon name="output" onClick={handleExport} title={capitalizeFirstLetter(t('export'))} classes={{ iconButton: 'pad-10px-imp' }} dark />
            </Header>
            <div className="pad-l-5px pad-r-5px fx-c h-100">
                <CustomTabs
                    selectedTab={selectedTab}
                    handleTabChange={handleTabChange}
                    tabLabels={[
                        t('reports'),
                        t('sites'),
                        t('report'),
                        t('site'),
                        t('workers'),
                        t('machines'),
                        t('tools'),
                        t('rentals'),
                        t('tempWorkers'),
                        t('subContractors'),
                        `${t('sites')} - ${t('trucks')}`,
                        `${t('sites')} - ${t('ampliroll')}`,
                        `${t('drivers')} - ${t('trucks')}`,
                        `${t('drivers')} - ${t('ampliroll')}`,
                    ]}
                />

                {selectedTab === 0 && (
                    <ReportsTable
                        activities={filteredActivitiesForConducs()}
                        refetch={refetch}
                        trucks={trucks}
                        dailyTrucksRides={dailyTrucksRides}
                        currentDate={currentDate}
                    />
                )}
                {selectedTab === 2 && (
                    <ReportTable
                        activities={filteredActivitiesForConducs()}
                        refetch={refetch}
                        trucks={trucks}
                        dailyTrucksRides={dailyTrucksRides}
                        currentDate={currentDate}
                    />
                )}
                {selectedTab === 3 && (
                    <SiteTable
                        activities={filteredActivitiesForConducs()}
                        refetch={refetch}
                        trucks={trucks}
                        dailyTrucksRides={dailyTrucksRides}
                        currentDate={currentDate}
                    />
                )}
                {selectedTab === 1 && (
                    <div className="parent-container">
                        <div className="table-container">
                            <table id="csv-planning-obj">
                                <thead>
                                    <tr>
                                        <th scope="col" className="w-30px pos-sy pad-0-imp left-0 z-idx-4">
                                            {capitalizeFirstLetter(t(''))}
                                        </th>
                                        <th scope="col" className="w-200px pos-sy left-30px z-idx-4">
                                            {capitalizeFirstLetter(t('site'))}
                                        </th>
                                        <th scope="col" className="w-150px">
                                            <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('status'))}</div>
                                        </th>
                                        <th scope="col" className="w-200px left-230px z-idx-4">
                                            {capitalizeFirstLetter(t('person'))}
                                        </th>
                                        <th scope="col" className="w-75px">
                                            {capitalizeFirstLetter(t('start'))}
                                        </th>
                                        <th scope="col" className="w-75px">
                                            {capitalizeFirstLetter(t('end'))}
                                        </th>
                                        <th scope="col" className="w-75px">
                                            {capitalizeFirstLetter(t('validatedAt'))}
                                        </th>
                                        <th scope="col" className="w-100px">
                                            <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('truckManager'))}</div>
                                        </th>
                                        <th scope="col" className="w-300px">
                                            {capitalizeFirstLetter(t('observationsPlanning'))}
                                        </th>
                                        <th scope="col" className="w-300px">
                                            {capitalizeFirstLetter(t('observationsWorker'))}
                                        </th>
                                        <th scope="col" className="w-300px">
                                            {capitalizeFirstLetter(t('machines'))}
                                        </th>
                                        <th scope="col" className="w-300px">
                                            {capitalizeFirstLetter(t('tools'))}
                                        </th>
                                        <th scope="col" className="w-300px">
                                            {capitalizeFirstLetter(t('rentals'))}
                                        </th>
                                        <th scope="col" className="w-200px">
                                            {capitalizeFirstLetter(t('tempWorkers'))}
                                        </th>
                                        <th scope="col" className="w-200px">
                                            {capitalizeFirstLetter(t('subContractors'))}
                                        </th>
                                        <th scope="col" className="w-200px">
                                            {capitalizeFirstLetter(t('activity'))}
                                        </th>
                                        {user && user?.profileId?.permsLevel <= permissionLevels.SUPERVISION && (
                                            <th scope="col" className="w-100px">
                                                {capitalizeFirstLetter(t('actions'))}
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody id="fu-planning-obj">
                                    {isSuccess && !filteredActivitiesForConducs().length && (
                                        <tr className="row-empty">
                                            <td className="cell-empty" colSpan={16}>
                                                {capitalizeFirstLetter(t('noActivity'))}
                                            </td>
                                        </tr>
                                    )}
                                    {filteredActivitiesForConducs()
                                        .sort((a, b) => {
                                            if (!(!a.siteId?.conducId?._id && !b.siteId?.conducId?._id)) {
                                                if (!a.siteId?.conducId?._id) return -1;
                                                if (!b.siteId?.conducId?._id) return 1;
                                            }
                                            if (a.siteId?.conducId?._id < b.siteId?.conducId?._id) return -1;
                                            if (a.siteId?.conducId?._id > b.siteId?.conducId?._id) return 1;
                                            const siteA = a.siteId.name.toLowerCase();
                                            const siteB = b.siteId.name.toLowerCase();
                                            if (siteA < siteB) return -1;
                                            if (siteA > siteB) return 1;
                                            return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
                                        })
                                        .map((activity, index, array) => (
                                            <React.Fragment key={activity._id}>
                                                {index > 0 && array[index - 1].siteId?._id !== activity.siteId?._id && (
                                                    <tr id="fu-exclude">
                                                        <td colSpan={20} style={{ backgroundColor: 'rgba(0,0,0,1)', height: 2, padding: 0 }} />
                                                    </tr>
                                                )}
                                                <ActivityWithRidesRow
                                                    activities={array}
                                                    refetch={refetch}
                                                    trucks={trucks}
                                                    dailyTrucksRides={dailyTrucksRides}
                                                    currentDate={currentDate}
                                                    index={index}
                                                    activity={activity}
                                                />
                                            </React.Fragment>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {selectedTab === 4 && <WorkersTable activities={filteredActivitiesForConducs()} universalFilter={universalFilter} />}
                {selectedTab === 5 && <MachinesTable activities={filteredActivitiesForConducs()} universalFilter={universalFilter} />}
                {selectedTab === 6 && <ToolsTable activities={filteredActivitiesForConducs()} universalFilter={universalFilter} />}
                {selectedTab === 7 && <RentalsTable activities={filteredActivitiesForConducs()} universalFilter={universalFilter} />}
                {selectedTab === 8 && <TempWorkersTable activities={filteredActivitiesForConducs()} universalFilter={universalFilter} />}
                {selectedTab === 9 && <SubContractorsTable activities={filteredActivitiesForConducs()} universalFilter={universalFilter} />}
                {selectedTab === 10 && (
                    <SitesRidesTable
                        activities={filteredActivitiesForConducs()}
                        dailyTrucksRides={dailyTrucksRides}
                        universalFilter={universalFilter}
                    />
                )}
                {selectedTab === 11 && (
                    <SitesAmplirollTable
                        activities={filteredActivitiesForConducs()}
                        dailyTrucksRides={dailyTrucksRides}
                        universalFilter={universalFilter}
                    />
                )}
                {selectedTab === 12 && (
                    <DriversRidesTable
                        activities={filteredActivitiesForConducs()}
                        dailyTrucksRides={dailyTrucksRides}
                        universalFilter={universalFilter}
                    />
                )}
                {selectedTab === 13 && (
                    <DriversAmplirollTable
                        activities={filteredActivitiesForConducs()}
                        dailyTrucksRides={dailyTrucksRides}
                        universalFilter={universalFilter}
                    />
                )}

                <div className="loading-bar">{isLoading && <LinearProgress color="inherit" />}</div>
                {showCalendarModal && (
                    <CalendarModal
                        currentDate={currentDate}
                        open={showCalendarModal}
                        handleClose={() => setShowCalendarModal(false)}
                        setCurrentDate={handleDateChange}
                    />
                )}
            </div>
        </div>
    );
};

export default ReportsHistory;
