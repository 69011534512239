import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cancelActivities, cancelActivity, DataItem, duplicateActivities, duplicateActivity } from '../../services/apiSLTP/activities';
import { Site, UserItem, deleteActivity, updateActivity } from '../../services/apiSLTP/planning';
import { capitalizeEveryFirstWordLetter, capitalizeFirstLetter } from '../../utils/capitalize';
import {
    getMaterialStateInfos,
    getStateInfos,
    localStorageKeys,
    reportState,
    stepModes,
    truckRideActivityTypeId,
} from '../../utils/enums';
import StartTimeCell from '../cells/StartTimeCell';
import ObservationsCell from '../cells/ObservationsCell';
import CreateActivityModal from '../modals/CreateActivityModal';
import PersonCell from '../cells/PersonCell';
import { useAuth } from '../../hooks/useAuth';
import { getMaterialStateColor, getStateColor, getTextColor } from '../../utils/colors';
import CustomToggle from '../CustomToggle';
import TruckIcon from '../TruckIcon';
import { Truck } from '../../services/apiSLTP/materials/trucks';
import ColorBar from '../ColorBar';
import StateBadge from '../StateBadge';
import Icon from '../Icon';
import TrucksPlannedCell from '../cells/TrucksPlannedCell';
import RemainingPeopleModal from '../modals/RemainingPeopleModal';
import RemainingMaterialModal from '../modals/RemainingMaterialModal';
import InfosColorsModal from '../modals/InfosColorsModal';
import ModalBody from '../modals/ModalBody';
import SiteCell from '../cells/SiteCell';
import { useMachinesWithLastLocationsQuery } from '../../hooks/queries/useMachinesQuery';
import { useToolsWithLastLocationsQuery } from '../../hooks/queries/useToolsQuery';
import StateCell from '../cells/StateCell';
import ExcavatorWhite from '../../assets/images/excavator-white.png';
import ToolsWhite from '../../assets/images/tools-white.png';
import { LinearProgress } from '@mui/material';

interface PlanningMaterialPageProps {
    sites: Site[];
    currentDate: Date;
    users: UserItem[];
    activitiesTypes: { label: string; value: string }[];
    trucks: Truck[];
    refetch: () => void;
    activities: DataItem[];
}

const PlanningMaterialPage: React.FC<PlanningMaterialPageProps> = ({ activitiesTypes, currentDate, refetch, sites, trucks, users, activities }) => {
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [selectedSiteId, setSelectedSiteId] = useState('');
    const [selectedAddress, setSelectedAddress] = useState<Site | null>(null);

    const { user } = useAuth();
    const { t } = useTranslation();

    const {
        data: dataMachinesWithLocations,
        isSuccess: isMachinesSuccess,
        isLoading: isMachinesLoading,
    } = useMachinesWithLastLocationsQuery({ date: currentDate });
    const {
        data: dataToolsWithLocations,
        isSuccess: isToolsSuccess,
        isLoading: isToolsLoading,
    } = useToolsWithLastLocationsQuery({ date: currentDate });

    const handleDeleteActivity = async (activityId: string) => {
        const response = await deleteActivity(activityId);
        refetch();
    };

    const submitData = async (activityId: string) => {
        //const response = await importActivitiesByDate(currentDate.toISOString(), reportState.PLANNING, reportState.REPORT);
        if (!activityId.length) {
            await duplicateActivities(currentDate.toISOString(), reportState.PLANNING, stepModes.PLANNING, stepModes.REPORT, reportState.REPORT);
        } else {
            await duplicateActivity(activityId, stepModes.REPORT, reportState.REPORT);
        }
        refetch();
    };

    const rollBackData = async (activityId: string) => {
        //const response = await importActivitiesByDate(currentDate.toISOString(), reportState.REPORT, reportState.PLANNING);
        if (!activityId.length) {
            await cancelActivities(currentDate.toISOString(), reportState.REPORT, stepModes.REPORT, stepModes.PLANNING, reportState.PLANNING);
        } else {
            await cancelActivity(activityId, reportState.REPORT, stepModes.REPORT);
        }
        refetch();
    };

    const handleActivityTruckManager = async (id: string, isTruckManager: boolean, siteId: string) => {
        await updateActivity(id, { isTruckManager });
        const currentTruckManager = activities?.find((value) => value.siteId._id === siteId && value.isTruckManager);
        if (currentTruckManager) await updateActivity(currentTruckManager._id, { isTruckManager: false });
        refetch();
    };

    const checkEditableRow = (activity: DataItem) => {
        if (activity.activityState <= reportState.PLANNINGSENT) return false;
        return true;
    };

    const setSpecificHour = () => {
        const date = new Date(currentDate);
        date.setHours(7, 0);
        return date.toISOString();
    };

    interface TotalsByTruckType {
        [truckTypeId: string]: number;
    }

    const calculateTruckTotalsByTruckType = (): TotalsByTruckType => {
        return activities.reduce<TotalsByTruckType>((acc, item) => {
            item.dailyTrucksPlanned.forEach((truck) => {
                const truckType = truck.truckTypeId.designation;

                acc[truckType] = (acc[truckType] || 0) + truck.trucksAmount;
            });

            return acc;
        }, {});
    };

    const filterAndSortTrucks = () => {
        return activities
            .filter((activity) => activity.activityTypeId._id === truckRideActivityTypeId)
            .sort((a, b) => {
                const truckA = trucks.find((truck) => truck.userId._id === a.reportId.userId._id)?.truckTypeId._id || '';
                const truckB = trucks.find((truck) => truck.userId._id === b.reportId.userId._id)?.truckTypeId._id || '';
                if (truckA < truckB) return -1;
                if (truckA > truckB) return 1;
                if (a.reportId.userId._id < b.reportId.userId._id) return -1;
                if (a.reportId.userId._id > b.reportId.userId._id) return 1;
                if (new Date(a.startTime).getTime() < new Date(b.startTime).getTime()) return -1;
                if (new Date(a.startTime).getTime() > new Date(b.startTime).getTime()) return 1;
                return 0;
            });
    };

    const compareTypePreviousLine = (index: number, array: DataItem[], activity: DataItem) => {
        return (
            index > 0 &&
            (trucks.find((truck) => truck.userId._id === array[index - 1].reportId.userId._id)?.truckTypeId._id || '') !==
                (trucks.find((truck) => truck.userId._id === activity.reportId.userId._id)?.truckTypeId._id || '')
        );
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr>
                        <th scope="col" className="w-200px ">
                            {capitalizeFirstLetter(t('name'))}
                        </th>
                        <th scope="col" className="w-200px ">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                        <th scope="col" className="w-150px">
                            <div className="fx-r fx-justify-c">{capitalizeFirstLetter(t('state'))}</div>
                        </th>
                        <th scope="col" className="w-75px">
                            {capitalizeFirstLetter(t('date'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    <tr className="" id="fu-exclude">
                        <td className="pos-sy left-0 pad-0-imp" colSpan={4}>
                            <div className="fx-r" style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}>
                                <img src={ExcavatorWhite} height={20} title={`Machine icon`} alt={`Machine icon`} style={{ padding: 5 }} />
                                <div className="fx-r fx-items-c pos-sy left-0 text-white">
                                    <div>{capitalizeFirstLetter(t('machines'))}</div>
                                </div>
                            </div>
                            <div className="loading-bar">{isMachinesLoading && <LinearProgress color="primary" />}</div>
                        </td>
                    </tr>
                    {isMachinesSuccess && !dataMachinesWithLocations?.length && (
                        <tr className="row-empty">
                            <td className="cell-empty" colSpan={4}>
                                {capitalizeFirstLetter(t('noMachine'))}
                            </td>
                        </tr>
                    )}
                    {dataMachinesWithLocations
                        ?.sort((a, b) => a.designation.localeCompare(b.designation))
                        ?.map((machineWithLocation, index, array) => (
                            <React.Fragment key={machineWithLocation._id}>
                                <tr>
                                    <td>{machineWithLocation.designation}</td>
                                    <td>{machineWithLocation.siteName}</td>
                                    <td>
                                        <StateCell
                                            stateColor={getMaterialStateColor(machineWithLocation.latestState)}
                                            text={capitalizeFirstLetter(t(getMaterialStateInfos(machineWithLocation.latestState)))}
                                        />
                                    </td>
                                    <td>{new Date(machineWithLocation.latestDate).toLocaleDateString(t('localeDate'))}</td>
                                </tr>
                            </React.Fragment>
                        ))}
                    <tr className="" id="fu-exclude">
                        <td className="pos-sy left-0 pad-0-imp" colSpan={4}>
                            <div className="fx-r" style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}>
                                <img src={ToolsWhite} height={20} title={`Machine icon`} alt={`Machine icon`} style={{ padding: 5 }} />
                                <div className="fx-r fx-items-c pos-sy left-0 text-white">
                                    <div>{capitalizeFirstLetter(t('tools'))}</div>
                                </div>
                            </div>
                            <div className="loading-bar">{isToolsLoading && <LinearProgress color="primary" />}</div>
                        </td>
                    </tr>
                    {isToolsSuccess && !dataToolsWithLocations?.length && (
                        <tr className="row-empty">
                            <td className="cell-empty" colSpan={4}>
                                {capitalizeFirstLetter(t('noTool'))}
                            </td>
                        </tr>
                    )}
                    {dataToolsWithLocations
                        ?.sort((a, b) => a.designation.localeCompare(b.designation))
                        ?.map((toolWithLocation, index, array) => (
                            <React.Fragment key={toolWithLocation._id}>
                                <tr>
                                    <td>{toolWithLocation.designation}</td>
                                    <td>{toolWithLocation.siteName}</td>
                                    <td>
                                        <StateCell
                                            stateColor={getMaterialStateColor(toolWithLocation.latestState)}
                                            text={capitalizeFirstLetter(t(getMaterialStateInfos(toolWithLocation.latestState)))}
                                        />
                                    </td>
                                    <td>{new Date(toolWithLocation.latestDate).toLocaleDateString(t('localeDate'))}</td>
                                </tr>
                            </React.Fragment>
                        ))}
                </tbody>
            </table>
            {showActivityModal && (
                <CreateActivityModal
                    activitiesTypes={activitiesTypes}
                    open={showActivityModal}
                    handleClose={() => setShowActivityModal(false)}
                    sites={sites.map((type) => {
                        return { label: type.name, value: type._id };
                    })}
                    users={users}
                    initDate={setSpecificHour()}
                    refetch={() => refetch()}
                    initSiteId={selectedSiteId}
                />
            )}
        </div>
    );
};

export default PlanningMaterialPage;
