import React, { useEffect, useState } from 'react';
import { useActivitiesByDateQuery } from '../hooks/queries/useActivitiesQuery';
import { useTranslation } from 'react-i18next';
import {
    DataItem,
    duplicateActivities,
    importActivitiesByConduc,
    importActivitiesByDate,
    importActivitiesBySite,
} from '../services/apiSLTP/activities';
import { capitalizeEveryFirstWordLetter, capitalizeFirstLetter } from '../utils/capitalize';
import { getStateInfos, localStorageKeys, permissionLevels, reportState, stepModes, truckRideActivityTypeId } from '../utils/enums';
import MachinesCell from '../components/cells/MachinesCell';
import RentalsCell from '../components/cells/RentalsCell';
import TempWorkersCell from '../components/cells/TempWorkersCell';
import SubContractorsCell from '../components/cells/SubContractorsCell';
import ToolsCell from '../components/cells/ToolsCell';
import CalendarModal from '../components/modals/CalendarModal';
import { getInOneDayDate, getOneDayAgoDate, getTomorrowDate, isRollbackPossible } from '../utils/dates';
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from '../hooks/useAuth';
import { getStateColor } from '../utils/colors';
import CustomToggle from '../components/CustomToggle';
import TruckIcon from '../components/TruckIcon';
import { useTrucksQuery } from '../hooks/queries/useTrucksQuery';
import { Truck } from '../services/apiSLTP/materials/trucks';
import ColorBar from '../components/ColorBar';
import { getCurrentConducs } from '../services/apiSLTP/sites/sites';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getItemFromLocalStorage, storeItemToLocalStorage } from '../utils/localStorage';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useConducsQuery } from '../hooks/queries/useSitesQuery';
import Icon from '../components/Icon';
import StateBadge from '../components/StateBadge';
import TrucksPlannedCell from '../components/cells/TrucksPlannedCell';
import InfosColorsModal from '../components/modals/InfosColorsModal';
import SiteCell from '../components/cells/SiteCell';
import StateCell from '../components/cells/StateCell';
import CustomTabs from '../components/CustomTabs';

const NeedsPage: React.FC = () => {
    const [currentDate, setCurrentDate] = useState(new Date(getItemFromLocalStorage(localStorageKeys.DATE) || getTomorrowDate()));
    const [dragIndex, setDragIndex] = useState({ activityIndex: 0, dailyIndex: 0 });
    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const [universalFilter, setUniversalFilter] = useState('');
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [conducs, setConducs] = useState<{ _id: string; firstName: string; lastName: string }[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);

    const { user } = useAuth();
    const { t } = useTranslation();
    const location = useLocation();

    const {
        data: activities,
        isLoading,
        isError,
        isSuccess,
        isRefetching,
        refetch,
        isFetching,
        isIdle,
    } = useActivitiesByDateQuery({
        date: currentDate.toISOString(),
        minState: reportState.NEEDS,
        maxState: reportState.PLANNINGSENT,
        stepMode: stepModes.NEEDS,
    });
    const { data: trucksData, isLoading: trucksIsLoading, isError: trucksIsError, isSuccess: trucksIsSuccess } = useTrucksQuery({});
    const { data: conducsData, isLoading: conducsIsLoading, isError: conducsIsError, isSuccess: conducsIsSuccess } = useConducsQuery({});

    const handleDateChange = (newDate: string) => {
        setCurrentDate(new Date(newDate));
        storeItemToLocalStorage(localStorageKeys.DATE, newDate);
    };

    const importData = async () => {
        /*!conducId.length
            ? await importActivitiesByDate(currentDate.toISOString(), reportState.NEEDSSENT, reportState.PLANNING)
            : await importActivitiesByConduc(conducId, currentDate.toISOString(), reportState.NEEDSSENT, reportState.PLANNING);*/
        await duplicateActivities(currentDate.toISOString(), reportState.NEEDSSENT, stepModes.NEEDS, stepModes.PLANNING, reportState.PLANNING);
        refetch();
    };

    const unfilledData = async () => {
        /*!conducId.length
            ? await importActivitiesByDate(currentDate.toISOString(), reportState.NEEDSSENT, reportState.NEEDS)
            : await importActivitiesByConduc(conducId, currentDate.toISOString(), reportState.NEEDSSENT, reportState.NEEDS);*/
        await importActivitiesByDate(currentDate.toISOString(), reportState.NEEDSSENT, reportState.NEEDS);
        //await importActivitiesByConduc(conducId, currentDate.toISOString(), reportState.NEEDSSENT, reportState.NEEDS);
        refetch();
    };

    const handleActivityState = async (siteId: string, state: number) => {
        //await updateActivity(activityId, { activityState: state });
        await importActivitiesBySite(siteId, currentDate.toISOString(), reportState.NEEDSSENT, state, stepModes.NEEDS);
        refetch();
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
        setSelectedTab(newValue);
        storeItemToLocalStorage(localStorageKeys.NEEDSTAB, newValue);
    };

    useEffect(() => {
        if (conducsData && conducsIsSuccess) setConducs(conducsData);
    }, [conducsData]);

    useEffect(() => {
        refetch();
    }, [location, refetch]);

    useEffect(() => {
        if (trucksData && trucksIsSuccess) {
            setTrucks(trucksData);
        }
    }, [trucksData]);

    useEffect(() => {
        if (conducsIsSuccess) {
            const tab = getItemFromLocalStorage(localStorageKeys.NEEDSTAB);
            //setSelectedTab();
        }
    }, [conducs]);

    return (
        <div className='fx-c h-100'>
            <Header
                isSearchable
                title={t('needs')}
                onSearch={(text) => setUniversalFilter(text)}
                searchValue={universalFilter}
                date={currentDate}
                onLeftArrowClick={() => handleDateChange(getOneDayAgoDate(currentDate).toISOString())}
                onRightArrowClick={() => handleDateChange(getInOneDayDate(currentDate).toISOString())}
            >
                <Icon
                    name="eventRepeat"
                    onClick={() => handleDateChange(new Date().toISOString())}
                    title={capitalizeFirstLetter(t('today'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="calendarMonth"
                    onClick={() => setShowCalendarModal(true)}
                    title={capitalizeFirstLetter(t('calendar'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <div className="divider" />
                {!!user && user?.profileId.permsLevel <= permissionLevels.PLANNING && (
                    <>
                        <Icon
                            name="addToHomeScreen"
                            onClick={() => unfilledData()}
                            title={capitalizeFirstLetter(t('sendBackNeeds'))}
                            classes={{ iconButton: 'pad-10px-imp' }}
                            dark
                        />
                        <Icon
                            name="verticalAlignBottom"
                            onClick={() => importData()}
                            title={capitalizeFirstLetter(t('importNeeds'))}
                            classes={{ iconButton: 'pad-10px-imp' }}
                            dark
                        />
                    </>
                )}
            </Header>
            <div className="pad-l-15px pad-r-15px fx-c h-100">
                <CustomTabs selectedTab={selectedTab} handleTabChange={handleTabChange} tabLabels={[t('all')]} />

                <div className="loading-bar">{isLoading && <LinearProgress color="inherit" />}</div>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col" className="w-30px pos-sy pad-0-imp left-0 z-idx-4">
                                    {capitalizeFirstLetter(t(''))}
                                </th>
                                <th scope="col" className="w-200px pos-sy left-30px z-idx-4">
                                    {capitalizeFirstLetter(t('site'))}
                                </th>
                                <th scope="col" className="w-75px">
                                    {capitalizeFirstLetter(t('start'))}
                                </th>
                                <th scope="col" className="w-200px left-230px z-idx-4">
                                    {capitalizeFirstLetter(t('person'))}
                                </th>
                                <th scope="col" className="w-280px">
                                    {capitalizeFirstLetter(t('machines'))}
                                </th>
                                <th scope="col" className="w-280px">
                                    {capitalizeFirstLetter(t('tools'))}
                                </th>
                                <th scope="col" className="w-280px">
                                    {capitalizeFirstLetter(t('rentals'))}
                                </th>
                                <th scope="col" className="w-200px">
                                    {capitalizeFirstLetter(t('subContractors'))}
                                </th>
                                <th scope="col" className="w-280px">
                                    {capitalizeFirstLetter(t('trucks'))}
                                </th>
                                <th scope="col" className="w-300px">
                                    {capitalizeFirstLetter(t('observations'))}
                                </th>
                                <th scope="col" className="w-200px">
                                    {capitalizeFirstLetter(t('tempWorkers'))}
                                </th>
                                <th scope="col" className="w-200px">
                                    {capitalizeFirstLetter(t('subContractors'))}
                                </th>
                                <th scope="col" className="w-100px">
                                    <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('truckManager'))}</div>
                                </th>
                                <th scope="col" className="w-200px">
                                    {capitalizeFirstLetter(t('activity'))}
                                </th>
                                <th scope="col" className="w-150px">
                                    <div className="fx-r fx-justify-c">{capitalizeFirstLetter(t('status'))}</div>
                                </th>
                                {!!user && user?.profileId.permsLevel <= permissionLevels.PLANNING && (
                                    <th scope="col" className="w-100px">
                                        {capitalizeFirstLetter(t('actions'))}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody id="fu-planning-obj">
                            {!activities?.length && (
                                <tr className="row-empty">
                                    <td className="cell-empty" colSpan={16}>
                                        {capitalizeFirstLetter(t('noActivity'))}
                                    </td>
                                </tr>
                            )}
                            {(activities || [])
                                .sort((a, b) => {
                                    if (!(!a.siteId?.conducId?._id && !b.siteId?.conducId?._id)) {
                                        if (!a.siteId?.conducId?._id) return -1;
                                        if (!b.siteId?.conducId?._id) return 1;
                                    }
                                    if (a.siteId?.conducId?._id < b.siteId?.conducId?._id) return -1;
                                    if (a.siteId?.conducId?._id > b.siteId?.conducId?._id) return 1;
                                    const siteA = a.siteId.name.toLowerCase();
                                    const siteB = b.siteId.name.toLowerCase();
                                    if (siteA < siteB) return -1;
                                    if (siteA > siteB) return 1;
                                    return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
                                })
                                .map((activity, index, array) => (
                                    <React.Fragment key={activity._id}>
                                        {index > 0 && array[index - 1].siteId?._id !== activity.siteId?._id && (
                                            <tr id="fu-exclude">
                                                <td colSpan={16} style={{ backgroundColor: 'rgba(0,0,0,1)', height: 2, padding: 0 }} />
                                            </tr>
                                        )}
                                        <tr
                                            className="row"
                                            style={{
                                                borderTopWidth: !(index > 0 && array[index - 1].siteId?._id === activity.siteId?._id) ? '1px' : '',
                                            }}
                                        >
                                            <td className="pos-sy left-0 pad-0-imp z-idx-2">
                                                <ColorBar
                                                    backgroundColor={activity.siteId?.conducId?.color || 'black'}
                                                    isPaddingTop={!(index > 0 && array[index - 1].siteId?._id === activity.siteId?._id)}
                                                    isPaddingBottom={
                                                        !(index < array.length - 1 && array[index + 1].siteId?._id === activity.siteId?._id)
                                                    }
                                                    title={
                                                        activity.siteId?.conducId?._id
                                                            ? capitalizeFirstLetter(activity.siteId?.conducId?.firstName) +
                                                              ' ' +
                                                              activity.siteId?.conducId?.lastName.toUpperCase()
                                                            : ''
                                                    }
                                                />
                                            </td>
                                            <td className="pos-sy left-30px z-idx-2">
                                                <SiteCell
                                                    activities={array}
                                                    activity={activity}
                                                    activityIndex={index}
                                                    currentDate={currentDate}
                                                    refetch={refetch}
                                                />
                                            </td>

                                            <td>
                                                {new Date(activity.startTime).getHours().toString().padStart(2, '0')}:
                                                {new Date(activity.startTime).getMinutes().toString().padStart(2, '0')}
                                            </td>
                                            <td className="pos-sy left-230px z-idx-2">
                                                {capitalizeFirstLetter(activity.reportId.userId.firstName)}{' '}
                                                {activity.reportId.userId.lastName.toUpperCase()}
                                            </td>

                                            <td>
                                                <MachinesCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled
                                                    refetch={refetch}
                                                    hiddenPlusIcon
                                                />
                                            </td>
                                            <td>
                                                <ToolsCell activity={activity} activityIndex={index} activities={array} disabled hiddenPlusIcon />
                                            </td>
                                            <td>
                                                <RentalsCell activity={activity} activityIndex={index} activities={array} disabled hiddenPlusIcon />
                                            </td>
                                            <td>
                                                <SubContractorsCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled
                                                    hiddenPlusIcon
                                                />
                                            </td>
                                            <td>
                                                <TrucksPlannedCell activity={activity} activityIndex={index} disabled hiddenPlusIcon />
                                            </td>
                                            <td>{activity.observationsPlanning}</td>
                                            <td>
                                                <TempWorkersCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled
                                                    hiddenPlusIcon
                                                />
                                            </td>
                                            <td>
                                                <SubContractorsCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled
                                                    hiddenPlusIcon
                                                />
                                            </td>
                                            <td>
                                                <div className="fx-r fx-justify-c">
                                                    {activity.activityTypeId._id !== truckRideActivityTypeId ? (
                                                        <CustomToggle
                                                            checked={activity.isTruckManager}
                                                            disabled
                                                            title={capitalizeFirstLetter(activity.isTruckManager ? t('yes') : t('no'))}
                                                        />
                                                    ) : (
                                                        <TruckIcon
                                                            type={
                                                                (trucks.find((value) => value.userId?._id === activity.reportId?.userId?._id)
                                                                    ?.truckTypeId.designation || '') as
                                                                    | 'Semie'
                                                                    | 'Ampliroll'
                                                                    | '8x4'
                                                                    | 'Porte-char'
                                                                    | ''
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </td>
                                            <td>{activity.activityTypeId.designation}</td>
                                            <td>
                                                <StateCell
                                                    stateColor={getStateColor(activity.activityState)}
                                                    text={capitalizeFirstLetter(t(getStateInfos(activity.activityState)))}
                                                />
                                            </td>
                                            {!!user && user?.profileId.permsLevel <= permissionLevels.PLANNING && (
                                                <td>
                                                    {activity.activityState === reportState.NEEDSSENT && (
                                                        <Icon
                                                            name="addToHomeScreen"
                                                            onClick={() => handleActivityState(activity.siteId._id, reportState.NEEDS)}
                                                            title={t('sendBackTheNeed')}
                                                        />
                                                    )}
                                                    {activity.activityState === reportState.NEEDSSENT && (
                                                        <Icon
                                                            name="verticalAlignBottom"
                                                            onClick={() => handleActivityState(activity.siteId._id, reportState.PLANNING)}
                                                            disabled
                                                            title={t('importTheNeed')}
                                                        />
                                                    )}
                                                </td>
                                            )}
                                        </tr>
                                    </React.Fragment>
                                ))}
                        </tbody>
                    </table>
                </div>
                {showCalendarModal && (
                    <CalendarModal
                        currentDate={currentDate}
                        open={showCalendarModal}
                        handleClose={() => setShowCalendarModal(false)}
                        setCurrentDate={handleDateChange}
                    />
                )}
            </div>
        </div>
    );
};

export default NeedsPage;
